<template>
  <layout-base>
    <app-header />

    <v-layout class="overflow-inherit!">
      <v-main>
        <slot />
      </v-main>
    </v-layout>

    <app-footer />

    <div class="md:hidden">
      <mobile-nav />
    </div>
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";
import AppHeader from "~~/components/layout/headers/SimpleHeader.vue";
import AppFooter from "~~/components/layout/footers/SimpleFooter.vue";
import MobileNav from "~~/components/layout/footers/MobileNav.vue";

export default defineComponent({
  components: {
    LayoutBase,
    AppHeader,
    AppFooter,
    MobileNav,
  },
});
</script>
